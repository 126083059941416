import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import Layout from '../components/layout/Layout';
import { Link } from 'gatsby';
import RebuildingModels from "../data/RebuildingModels"
import PricingCard from '../components/PricingCard';

const Index = () => (
  <Layout>
<section id="features" className="py-20 lg:pb-16 lg:pt-16">
      <div className="container mx-auto text-center">
        <h2 className="text-4xl lg:text-5xl font-semibold">Rebuilding Pricing Models</h2>
        <div className="d-flex flex flex-col sm:flex-row sm:-mx-3 mt-12 align-items-stretch">
        <div className="flex flex-col md:flex-row md:-mx-3 text-lg">
          {RebuildingModels.map(pricing => (
            <div key={pricing.title} className="flex-1 px-3">
              <PricingCard pricing={pricing} />
            </div>
          ))}
        </div>
        </div>
      </div>
    </section>  
  </Layout>
);

export default Index;
