export default [
  {
    title: 'REBUILD & MANAGEMENT',
    items:
      [
        {
          value: "Full website rebuild"
        },
        {
          value: "Hosting"
        },
        {
          value: "Website support and maintenance"
        },
        {
          value: "Branding campaign strategy launch & management"
        },
        {
          value: "Tailored strategic plan"
        },
        {
          value: "Account setup included"
        },
        {
          value: "4x Posts per week"
        },
        {
          value: "1x Image advert"
        },
        {
          value: "1x Blog Post"
        },
        {
          value: "2x Social media platforms included (Instagram, Facebook etc.)"
        },
        {
          value: "Post scheduling"
        },
        {
          value: "Monthly content mapping"
        },
        {
          value: "Monthly strategy meeting"
        },
        {
          value: "Analytics reporting"
        },
        {
          value: "Monthly reporting"
        }
      ],
    price: 'Starting at R5 000.00 (Dependant on needs)',
  },
  {
    title: 'FULL REBUILD & ECOMMERCE',
    items:
    [
      {
        value: "Full website rebuild"
      },
      {
        value: "eCommerce site build"
      },
      {
        value: "Payment processing setup"
      },
      {
        value: "Hosting"
      },
      {
        value: "Website support and maintenance"
      },
      {
        value: "Branding campaign strategy launch & management"
      },
      {
        value: "Tailored strategic plan"
      },
      {
        value: "Account setup included"
      },
      {
        value: "4x Posts per week"
      },
      {
        value: "1x Image advert"
      },
      {
        value: "1x Blog Post"
      },
      {
        value: "2x Social media platforms included (Instagram, Facebook etc.)"
      },
      {
        value: "Post scheduling"
      },
      {
        value: "Monthly content mapping"
      },
      {
        value: "Monthly strategy meeting"
      },
      {
        value: "Analytics reporting"
      },
      {
        value: "Monthly reporting"
      }
    ],
    price: 'Starting at R10 000.00 (Dependant on needs)',
  },
  
];
